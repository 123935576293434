body {
  background-color: black;
  color: white;
}


.affiliate.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.affiliate.container > * {
  margin: 0;
}


.header.image {
  display: inline-block;
  position: sticky;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  height: 7vh;
}


.lead.container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.lead.container > * {
  margin: 0;
}


.map.container {
  position: relative;
  display: block;
  height: 50vh;
  left: 5vw;
  width: 90vw;
}

.map.marker {
  cursor: pointer;
  color: #EEB735;
}